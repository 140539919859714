@import 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Lexend:wght@500&display=swap';

@font-face {
  font-family: 'Polysans_Qonto_Slim';
  src: url('../../../assets/fonts/polysansqonto-slim-webfont.woff2') format('woff2'),
  url('../../../assets/fonts/polysansqonto-slim-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.highlight {
  background-color: var(--yellow-300);
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

body {
  font-family: $font-body;
  font-size: rem-calc($body-size);
  color: var(--gray);

  &.gray {
    background-color: var(--gray-200);
  }
}

.dark-mode body {
  color: var(--gray);
}
