:root {
  @each $key, $value in $colors {
    --#{$key}: #{$value};
    --#{$key}-rgb: #{derive-rgb-values-from-hex-modern($value)};
  }
}

.dark-mode {
  color-scheme: dark;

  @each $key, $value in $colors-dark-mode {
    --#{$key}: #{$value};
    --#{$key}-rgb: #{derive-rgb-values-from-hex-modern($value)};
  }
}

.color-primary {
  color: var(--primary);
}

.color-primary-700 {
  color: var(--primary-700);
}

.color-primary-500 {
  color: var(--primary-500);
}

.color-admin {
  color: var(--purple);
}

.color-purple {
  color: var(--purple);
}

.color-purple-200 {
  color: var(--purple-200);
}

.color-purple-700 {
  color: var(--purple-700);
}

.color-gray {
  color: var(--gray);
}

.color-gray-300 {
  color: var(--gray-300);
}

.color-gray-325 {
  color: var(--gray-325);
}

.color-gray-350 {
  color: var(--gray-350);
}

.color-gray-700 {
  color: var(--gray-700);
}

.color-success {
  color: var(--green);
}

.color-green-200 {
  color: var(--green-200);
}

.color-green-500 {
  color: var(--green-500);
}

.color-green-700 {
  color: var(--green-700);
}

.color-error {
  color: var(--red);
}

.color-red-200 {
  color: var(--red-200);
}

.color-red-500 {
  color: var(--red-500);
}

.color-red-700 {
  color: var(--red-700);
}

.color-warning {
  color: var(--yellow);
}

.color-white {
  color: var(--white);
}

.color-yellow-500 {
  color: var(--yellow-500);
}

.color-yellow-700 {
  color: var(--yellow-700);
}

.color-black-qonto {
  color: var(--qonto-black);
}

.m-orange {
  color: var(--yellow);
  background-color: var(--yellow);
}

.m-yellow {
  color: var(--yellow-300);
  background-color: var(--yellow-300);
}

.m-pink {
  color: var(--pink);
  background-color: var(--pink);
}

.m-purple {
  color: var(--purple);
  background-color: var(--purple);
}

.m-red {
  color: var(--red);
  background-color: var(--red);
}

.m-blue {
  color: var(--primary);
  background-color: var(--primary);
}

.m-grey-dark,
.m-gray {
  color: var(--gray);
  background-color: var(--gray);
}

.m-primary-700 {
  color: var(--primary-700);
  background-color: var(--primary-700);
}

.m-green {
  color: var(--green);
  background-color: var(--green);
}
