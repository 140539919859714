.card {
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: 4px;

  &__body {
    padding: 16px 24px;
    border-top: 1px solid var(--gray-300);

    &.no-padding {
      padding: 0;
    }
  }

  &__header {
    background-color: var(--gray-100);
    padding: 16px 24px;
    border-radius: 4px 4px 0 0;

    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.small {
      padding: 8px;
    }

    &.no-padding {
      padding: 0;
    }

    &.border-top {
      border-top: 1px solid var(--gray-300);
      border-radius: 0;
    }

    &.no-border {
      border-bottom: none;
      border-radius: 0;
    }
  }

  &__footer {
    padding: 16px 24px;
    border-top: 1px solid var(--gray-300);
    background-color: var(--gray-100);
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-gap: 16px;
  }
}
