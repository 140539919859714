@import '../abstracts';

.invoice-drawer {
  display: grid;
  height: 100vh;
  overflow: hidden;
  grid-template-columns: minmax(600px, 60%) minmax(425px, 40%);

  &__right {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;

      &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
      }

      .invoice-title {
        word-break: break-word;
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-shadow: 0 4px 8px var(--gray);
      z-index: 1;
    }
  }
}


