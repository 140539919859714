.table {
  overflow: hidden;

  &.overflow {
    overflow: visible;
  }

  &.collapse {
    position: relative;

    &::after {
      position: absolute;
      height: 40px;
      width: 100%;
      bottom: 0;
      content: "";
      background: linear-gradient(0deg, rgba(var(--white-rgb) / 100%) 0%, rgba(var(--white-rgb) / 0%) 100%);
    }
  }

  table {
    width: 100%;
    border-spacing: 0;

    thead {
      th {
        font-family: $font-title, sans-serif;
        font-size: rem-calc(14);
        color: var(--primary-700);
        background-color: var(--gray-100);
        font-weight: 600;
        border-top: 1px solid var(--gray-300);
        border-bottom: 1px solid var(--gray-300);
        text-align: left;

        &:not(.custom-padding) {
          padding: rem-calc(8) rem-calc(24);
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-left: 1px solid var(--gray-300);
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-right: 1px solid var(--gray-300);
          text-align: right;
        }

        &.left {
          text-align: left;
        }

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }
      }
    }

    tfoot {
      tr {
        background-color: var(--gray-100);
      }

      td {
        border-bottom: 1px solid var(--gray-300);
        border-right: 1px solid var(--gray-300);
        border-left: 1px solid var(--gray-300);
        padding: rem-calc(8) rem-calc(24);
        border-radius: 0 0 4px 4px;

        &.center {
          text-align: center;
        }

        &.left {
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        background-color: var(--white);

        &:not(.not-stripped):nth-child(even) {
          background-color: var(--gray-100);
        }

        &:not(.not-stripped):nth-child(odd) {
          background-color: var(--white);
        }
      }

      td {
        font-size: rem-calc(14);
        color: var(--gray);

        &:not(.no-border) {
          border-bottom: 1px solid var(--gray-300);
        }

        &.width-50 {
          width: 50%;
        }

        &:not(.custom-padding) {
          padding: rem-calc(8) rem-calc(24);
        }

        &:first-child {
          border-left: 1px solid var(--gray-300);
        }

        &:last-child {
          text-align: right;
          border-right: 1px solid var(--gray-300);
        }

        &.right {
          text-align: right;
        }

        &.center {
          text-align: center;
        }

        &.left {
          text-align: left;
        }

        &.primary-700 {
          color: var(--primary-700);
        }

        &.admin {
          color: var(--purple);
        }
      }
    }
  }
}
