.accounting-survey-card {
  counter-reset: survey-section;

  .panel-info-success {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 16px;

    img {
      width: 193px;
    }
  }
}

dougs-accounting-survey-question {
  display: block;

  &.with-previous {
    &::before {
      content: '';
      display: block;
      margin-top: 24px;
      border-top: 1px solid var(--gray-300);
      padding-top: 24px;
    }
  }
}

.accounting-survey-question {
  display: flex;
  grid-column-gap: 16px;

  &:not(.hidden) {
    &::before {
      counter-increment: survey-section;
      content: counter(survey-section);
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      background-color: var(--primary-700);
      border-radius: 50%;
      flex-shrink: 0;
      font-weight: 600;
      margin-left: 8px;
    }
  }
}

.accounting-survey {
  .table table {
    thead th {
      vertical-align: bottom;
    }

    thead th:not(.custom-padding),
    tbody td:not(.custom-padding),
    tfoot td:not(.custom-padding)
    {
      padding: rem-calc(8);

      &:first-child {
        padding-left: rem-calc(16);
      }

      &:last-child {
        padding-right: rem-calc(16);
      }
    }
  }
}

.form-line {
  display: flex;
  grid-column-gap: 16px;
  align-items: flex-start;
}

.operation-form {
  border-bottom: 1px solid var(--gray-300);
}
