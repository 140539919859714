label {
  cursor: pointer;
}

.label {
  cursor: pointer;
  display: block;
  font-size: rem-calc(14);
  line-height: 110%;
  margin-bottom: rem-calc(4);

  &.admin {
    color: var(--purple);
  }
}
