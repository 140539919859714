.skeleton {
  width: 100%;
  background: linear-gradient(
      270deg,
      rgb(var(--gray-300-rgb) / 100%),
      rgb(var(--gray-300-rgb) / 100%),
      rgb(var(--gray-300-rgb) / 30%),
      rgb(var(--gray-300-rgb) / 100%),
      rgb(var(--gray-300-rgb) / 100%)
  );
  background-size: 200% 200%;
  animation: gradient-animation 3s ease infinite;
  border-radius: 4px;

  &.title {
    height: 26px;
    border-radius: 13px;
  }

  &.subtitle {
    height: 14px;
  }

  &.paragraph {
    height: 101px;
  }

  &.button {
    height: 37px;
  }

  &.list-item {
    height: 74px;
  }

  &.ellipse {
    border-radius: 200px;
  }

  &.round {
    border-radius: 50%;
  }

  &.square {
    border-radius: 4px;
  }

  &.yellow {
    background: linear-gradient(
        270deg,
        rgb(var(--yellow-200-rgb) / 100%),
        rgb(var(--yellow-200-rgb) / 100%),
        rgb(var(--yellow-200-rgb) / 40%),
        rgb(var(--yellow-200-rgb) / 100%),
        rgb(var(--yellow-200-rgb) / 100%)
    );
    background-size: 200% 200%;
    animation: gradient-animation 3s ease infinite;
  }

  &.red {
    background: linear-gradient(
        270deg,
        rgb(var(--red-rgb) / 100%),
        rgb(var(--red-rgb) / 100%),
        rgb(var(--red-rgb) / 40%),
        rgb(var(--red-rgb) / 100%),
        rgb(var(--red-rgb) / 100%)
    );
    background-size: 200% 200%;
    animation: gradient-animation 3s ease infinite;
  }

  &.primary {
    background: linear-gradient(
        270deg,
        rgb(var(--primary-rgb) / 100%),
        rgb(var(--primary-rgb) / 100%),
        rgb(var(--primary-rgb) / 40%),
        rgb(var(--primary-rgb) / 100%),
        rgb(var(--primary-rgb) / 100%)
    );
    background-size: 200% 200%;
    animation: gradient-animation 3s ease infinite;
  }

  &.primary-700 {
    background: linear-gradient(
        270deg,
        rgb(var(--primary-700-rgb) / 100%),
        rgb(var(--primary-700-rgb) / 100%),
        rgb(var(--primary-700-rgb) / 40%),
        rgb(var(--primary-700-rgb) / 100%),
        rgb(var(--primary-700-rgb) / 100%)
    );
    background-size: 200% 200%;
    animation: gradient-animation 3s ease infinite;
  }
}

.skeleton-table-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.skeleton-mono-direction {
  width: 100%;
  background: linear-gradient(
      270deg,
      rgb(var(--gray-300-rgb) / 100%),
      rgb(var(--gray-300-rgb) / 100%),
      rgb(var(--gray-300-rgb) / 30%),
      rgb(var(--gray-300-rgb) / 100%),
      rgb(var(--gray-300-rgb) / 100%)
  );
  background-size: 200% 200%;
  animation: gradient-animation-monodirection 3s ease infinite;
  border-radius: 4px;
}

@keyframes gradient-animation-monodirection {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
