// Mixins file
@mixin breakpoint($breakpoint) {
  @media #{$breakpoint} {
    @content;
  }
}

@mixin ball-pulse($n:3, $start:0) {
  @for $i from $start through $n {
    &:nth-child(#{$i}) {
      animation: loader-scale 0.75s ($i * 0.12s) infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
  }
}
