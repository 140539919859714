.modal {
  display: flex;
  position: absolute;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.cdk-overlay-backdrop {
  background-color: rgba(var(--primary-700-rgb) / 80%);

  &.closing {
    opacity: 0;
  }
}

.dark-mode .cdk-overlay-backdrop {
  background-color: rgb(0 0 0 / 80%);
}

.global-overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.modal-title {
  padding: rem-calc(16) rem-calc(32);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-100);
  grid-column-gap: 16px;
  max-width: 100%;

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:has(+ .modal-content.small) {
    width: 362px;
  }

  &:has(+ .modal-content.medium) {
    width: 690px;
  }

  &:has(+ .modal-content.large) {
    width: 100%;
  }

  &:has(+ .modal-content.xlarge) {
    width: 1190px;
  }

  &:has(+ .modal-content.xxlarge) {
    width: 1900px;
  }
}

.modal-close {
  color: var(--primary-700);
  cursor: pointer;
}

.modal-content {
  padding: rem-calc(24) rem-calc(32) 0 rem-calc(32);
  max-height: 80vh;
  overflow: auto;
  font-size: rem-calc(14);
  max-width: 100%;

  &.force-max-height {
    min-height: 80vh;
  }

  &.modal-section:not(.no-padding):last-of-type {
    padding-bottom: rem-calc(24);
  }

  &.no-padding {
    padding: 0;
  }

  &.small {
    width: 362px;
  }

  &.medium {
    width: 690px;
  }

  &.large {
    width: 900px;
  }

  &.xlarge {
    width: 1190px;
  }

  &.xxlarge {
    width: 1900px;
  }
}

.modal-footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column-gap: 8px;
  padding: rem-calc(16) rem-calc(32);
  justify-content: flex-end;

  &[align="center"] {
    justify-content: center;
  }
}

.modal-footer-shadow {
  box-shadow: 0 0 16px 0 rgb(var(--black-rgb) / 15%);
}

.modal-hide {
  cursor: pointer;
}

.cdk-overlay-container-hide {
  opacity: 0.13;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
  margin: rem-calc(16) 0;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

@media #{$small-only} {
  .modal-content {
    max-height: 60vh;
    padding: rem-calc(16);
  }

  .modal {
    padding: rem-calc(8);
  }
}
