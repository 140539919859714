@use "sass:map";
@import "../abstracts";

.sign-layout {
  display: flex;
  height: 100vh;
  height: 100svh;
  overflow: auto;
  background: var(--white);

  @media #{$large-up} {
    min-height: 100vh;
    min-height: 100svh;
  }

  @include breakpoint($xlarge-up) {
    height: 100vh;
    height: 100svh;
  }

  .sidebar {
    display: none;
    width: 450px;
    background-color: var(--gray-200);

    &.dark {
      background-color: var(--primary-700);
    }

    @include breakpoint($xlarge-up) {
      display: flex;
      padding: 0 rem-calc(map.get($spacers, 40));
      flex-direction: column;
    }

    &__logo {
      padding: rem-calc(map.get($spacers, 24)) 0;
      display: flex;
      justify-content: space-between;
    }

    &__partner {
      display: flex;
      align-items: center;
      gap: 16px;
      color: var(--white);
      font-size: rem-calc(12);

      img {
        max-height: 14px;
      }
    }

    &__image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: auto;

      img {
        width: fit-content;
        height: 500px;
      }
    }

    &__description {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: auto;
    }
  }

  .body {
    flex: 1;
    padding: 0 rem-calc(map.get($spacers, 16));
    background: var(--white);
    width: 100%;
    max-width: rem-calc(map.get($breakpoints, large));
    margin: 0 auto;

    @include breakpoint($large-up) {
      padding: 0 rem-calc(map.get($spacers, 80));
    }

    @include breakpoint($xlarge-up) {
      display: flex;
      flex-direction: column;
    }

    @media #{$small-only} {
        display: flex;
        flex-direction: column;
    }

    &__content {
      display: flex;
      align-items: center;
      flex: 1;
      max-width: 530px;
      padding: 0 1rem;
      overflow-y: auto;

      @media #{$small-only} {
        padding-top: rem-calc(8);
      }

      &--full-width {
        max-width: none;
      }

      &--top {
        display: block;
        align-items: normal;
      }

      &__wrapper {
        width: 100%;
      }
    }

    &__footer {
      position: relative;
      display: flex;
      padding: 16px;
      align-items: center;
      font-size: rem-calc(14);
      flex-direction: column-reverse;
      justify-content: center;
      text-align: center;

      @media #{$large-up} {
        flex-direction: row;
        justify-content: space-between;
        padding: 32px 16px 40px;
      }

      p {
        margin-top: 8px;

        @media #{$large-up} {
          margin-top: 0;
        }
      }

      a {
        color: var(--primary);
        text-decoration: none;

        &.previous-link {
          color: var(--primary-700);
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.no-inner-scroll {
    height: auto;

    .body {
        &__content {
            overflow-y: visible;
        }
    }
  }
}
