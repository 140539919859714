.account-container {
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid transparent;

  &.disabled {
    opacity: 0.4;
  }

  .account-title {
    display: flex;
    align-items: center;

    &__bank-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .account-number {
    font-size: rem-calc(11);
    font-weight: 400;
    white-space: nowrap;
  }

  .account-balance-container {
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;

    i {
      font-weight: normal;
      font-size: rem-calc(12);
    }
  }

  dougs-memo {
    display: inline-block;
  }

  .account-detail-border {
    border-left: 3px solid transparent;
  }

  &.m-green {
    background-color: rgba(var(--green-rgb) / 10%);
    color: inherit;

    .account-detail-border {
      border-color: var(--green);
    }
  }

  &.m-blue {
    color: inherit;
    background-color: var(--primary-100);

    .account-detail-border {
      border-color: var(--primary);
    }
  }

  &.m-orange {
    color: inherit;
    background-color: rgba(var(--yellow-rgb) / 10%);

    .account-detail-border {
      border-color: var(--yellow);
    }
  }

  &.m-yellow {
    color: inherit;
    background-color: rgba(var(--yellow-300-rgb) / 10%);

    .account-detail-border {
      border-color: var(--yellow-300);
    }
  }

  &.m-red {
    color: inherit;
    background-color: rgba(var(--red-rgb) / 10%);

    .account-detail-border {
      border-color: var(--red);
    }
  }

  &.m-purple {
    color: inherit;
    background-color: rgba(var(--purple-rgb) / 10%);

    .account-detail-border {
      border-color: var(--purple);
    }
  }

  &.m-pink {
    color: inherit;
    background-color: rgba(var(--pink-rgb) / 10%);

    .account-detail-border {
      border-color: var(--pink);
    }
  }

  &.m-grey-dark, &.m-gray {
    color: inherit;
    background-color: var(--gray-200);

    .account-detail-border {
      border-color: var(--gray);
    }
  }

  &:hover {
    &.m-green {
      border-color: var(--green);
    }

    &.m-blue {
      border-color: var(--primary);
    }

    &.m-orange {
      border-color: var(--yellow);
    }

    &.m-yellow {
      border-color: var(--yellow-300);
    }

    &.m-red {
      border-color: var(--red);
    }

    &.m-purple {
      border-color: var(--purple);
    }

    &.m-pink {
      border-color: var(--pink);
    }

    &.m-grey-dark, &.m-gray {
      border-color: var(--gray);
    }
  }
}
