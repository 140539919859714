@import "libs/assets/styles/abstracts";

.settings-sub-menu {
  position: relative;

  $current: &;

  margin: 0 -32px;
  overflow: hidden;
  border-bottom: 1px solid var(--gray-300);

  @media #{$large-down} {
    margin: 0 -16px;
    padding: 0;
  }

  &__shadow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;

    &.end {
      right: 0;
      background: linear-gradient(90deg, rgba(var(--white-rgb) / 0%) 0%, var(--white) 70%);
    }

    &.start {
      left: 0;
      background: linear-gradient(270deg, rgba(var(--white-rgb) / 0%) 0%, var(--white) 70%);
    }
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.scroll-start-done ~ #{$current}__shadow.start {
      display: none;
    }

    &.scroll-end-done ~ #{$current}__shadow.end {
      display: none;
    }

    &.dragging {
      cursor: grab;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    a {
      display: block;
      padding: 0 16px 8px;
      text-decoration: none;
      font-size: rem-calc(14);
      color: var(--gray);
      border-bottom: 1px solid transparent;
      white-space: nowrap;
      position: relative;

      .missing-field-animation {
        right: -3px;
        left: auto;
        top: 3px;
        position: absolute;
      }

      &:hover, &.active {
        border-bottom-color: var(--primary-700);
        color: var(--primary-700);
      }

      &.admin {
        color: var(--purple);

        &:hover, &.active {
          border-bottom-color: var(--purple);
        }
      }
    }
  }
}
