.stepper {
  .step-subtitle {
    color: var(--step-text-color);
    font-size: rem-calc(11);
    transition: color 0.5s;
  }

  &.vertical {
    .step-subtitle {
      margin-bottom: 24px;
    }
  }

  &.tiny {
    .step-subtitle {
      display: none;
    }
  }
}
