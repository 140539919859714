@import "../abstracts";

.settings-subscription {
  .subscription-line {
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 350px));
    max-width: 75%;
    grid-column-gap: 16px;
    align-items: center;
  }

  .line-title {
    font-weight: 400;
    font-size: rem-calc(14);
    color: var(--primary-700);
  }

  .line-content {
    font-size: rem-calc(14);
    color: var(--gray);
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 8px;
    max-width: 18%;
    width: 100%;
  }

  .self-end {
    justify-self: flex-end;
  }
}
