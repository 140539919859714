.notification-reminder {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 16px 40px;
  text-align: center;

  p {
    font-size: rem-calc(15);
  }

  &.is-success {
    background-color: var(--green-200);
  }

  &.is-warning {
    background-color: var(--yellow-200);
  }

  &.is-info {
    background-color: var(--primary-200);
  }
}
