@import "../abstracts";

.form-field-control {
  background-color: var(--white);
  color: var(--primary-700);
  display: block;
  font-family: inherit;
  font-size: rem-calc(14);
  width: 100%;
  transition: border-color 200ms ease-in-out;
  border: 1px solid var(--gray-300);
  outline: none;
  padding: rem-calc(6) rem-calc(8);
  border-radius: 4px;

  &.no-border {
    border: 1px solid transparent;
  }

  &.pr-24 {
    padding-right: rem-calc(24);
  }

  &.to-filled {
    background-color: var(--primary-100);
    border-color: var(--primary-200);

    i {
      color: var(--primary);
    }
  }

  &.compact {
    padding: rem-calc(4) rem-calc(6);
    font-size: rem-calc(12);
  }

  &.has-focus {
    border-color: var(--primary);
  }

  &:focus {
    border-color: var(--primary);
  }

  &:disabled,
  &.disabled {
    background-color: var(--gray-200);
    color: var(--gray);
    cursor: not-allowed;

    &.no-border {
      cursor: default;
      background-color: var(--white);
      color: var(--primary-700);
      border: none;
      border-radius: 0;
      resize: none;
    }
  }

  &::placeholder,
  .placeholder {
    color: var(--gray-325);
  }

  &.ng-touched.ng-invalid {
    border-color: var(--red);
  }
}

.form-field {
  $form-field: &;

  &__wrapper {
    display: block;
    width: 100%;

    &.large {
      #{$form-field}-control {
        padding: rem-calc(12) rem-calc(8);
      }

      #{$form-field}__suffix,
      #{$form-field}__prefix {
        font-size: rem-calc(18);
        padding: 0 rem-calc(24);
        border-radius: 0 8px 8px 0;
      }

      #{$form-field}__prefix {
        border-radius: 8px 0 0 8px;
      }
    }

    &.small {
      #{$form-field}-control {
        padding: rem-calc(1) rem-calc(4);
        font-size: rem-calc(12);

        &:disabled,
        &.disabled {
          &.no-border {
            padding: 0;
          }
        }
      }

      .label {
        font-size: rem-calc(12);
      }

      #{$form-field}__suffix,
      #{$form-field}__prefix {
        font-size: rem-calc(12);
        padding: 0 rem-calc(3);
        border-radius: 0 2px 2px 0;
      }

      #{$form-field}__prefix {
        border-radius: 2px 0 0 2px;
      }
    }
  }

  &__suffix,
  &__prefix {
    display: flex;
    align-items: center;
    padding: 0 rem-calc(8);
    margin-bottom: 0;
    background: var(--gray-200);
    border-radius: 0 4px 4px 0;
    color: var(--primary-700);
    font-size: rem-calc(14);
    border: 1px solid var(--gray-300);
    transition: border-color ease-in 100ms, background ease-in 100ms, color ease-in 100ms;
    flex: 0 0 auto;

    &.no-border {
      border: none;
      background: none;
    }
  }

  &__prefix {
    border-radius: 4px 0 0 4px;
  }

  &__errors {
    position: relative;
    color: var(--red);
    font-size: rem-calc(12);

    & * > * + *::before {
      content: ',';
    }
  }

  &__content {
    display: flex;

    &.has-prefix {
      .form-field-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }
    }

    &.has-error {
      .form-field-control {
        border-color: var(--red);
      }

      .form-field {
        &__suffix, &__prefix {
          color: var(--red);
          border-color: var(--red);
        }
      }
    }

    &.has-suffix {
      .form-field-control:not(.no-border) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }

    &:focus-within {
      .form-field {
        &__suffix:not(.no-border) {
          color: var(--primary);
          border-color: var(--primary);
        }

        &__prefix {
          color: var(--primary);
          border-color: var(--primary);
        }
      }
    }
  }
}

.form-field-line {
  display: flex;
  grid-auto-columns: auto;
  align-items: flex-start;
  gap: 16px;

  @media #{$small-only} {
    flex-wrap: wrap;
  }
}

.character-count {
  text-align: right;
  font-size: rem-calc(12);
}
