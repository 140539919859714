@import '../abstracts';

.default-header {
  &__container {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
  }

  &__first-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: rem-calc(4);
    justify-content: flex-start;
  }

  &__right {
    display: flex;
    align-items: flex-start;
    gap: rem-calc(32);

    &.baseline {
      align-items: baseline;
    }

    &__first-column, &__second-column {
      display: flex;
      align-items: baseline;
      gap: rem-calc(8);
    }
  }

  &__second-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__period {
      i {
        font-size: rem-calc(10);
      }
    }
  }
}


.partner-header {
  &__container {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
  }

  &__first-row {
    display: flex;
    justify-content: space-between;
  }

  &__second-row {
    display: flex;
    justify-content: flex-end;
  }

  &__partner {
    display: flex;
    gap: rem-calc(8);
    justify-self: flex-start;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: rem-calc(2);
    }

    &__company-default-logo {
      height: rem-calc(48);
      width: rem-calc(48);
      color: var(--primary-700);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--gray-300);
      background-color: var(--gray-300);
    }
  }

  &__values {
    display: flex;
    gap: rem-calc(8);

    &__primary-value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-right: 1px solid var(--gray-300);
    }

    &__secondary-values {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;

      &__row {
        display: flex;
        gap: rem-calc(4);
        align-items: center;

        i {
          font-size: rem-calc(12);
        }
      }
    }
  }


  &__period {
    i {
      font-size: rem-calc(10);
    }
  }
}
