/* stylelint-disable no-descending-specificity */
body.has-extra-padding-bottom .body {
  padding-bottom: rem-calc(93); // Demo footer height (77px) + spacing (16px)
}

.modal-opened {
  .sidebar {
    .header,
    .banner,
    .body,
    .footer {
      @media #{$small-only} {
        overflow: hidden;
      }
    }
  }
}

.sidebar {
  .search-bar {
    position: sticky;
    top: 0;
    z-index: 3;
    transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

    @media #{$xlarge-up} {
      left: #{$sidebar-width};
      width: calc(100% - (#{$sidebar-width}));
    }
  }

  .header,
  .banner,
  .footer {
    position: relative;
    background-color: var(--white);
    transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

    @media #{$xlarge-up} {
      left: #{$sidebar-width};
      width: calc(100% - #{$sidebar-width});
    }

    &__container {
      max-width: $body-width;
      padding: rem-calc(24) 60px;
      margin: 0 auto;

      &.no-padding {
        padding: 0;
      }

      &.no-margin {
        margin: 0;
      }

      &.small-padding {
        padding: rem-calc(16) 60px;
      }

      &.container-fluid {
        max-width: 100%;

        &.no-padding {
          padding: 0;
        }
      }
    }
  }

  .footer {
    &__container {
      padding: rem-calc(16) 60px;
    }
  }

  .body {
    --body-padding: 1rem;

    position: relative;
    padding: 0 var(--body-padding) var(--body-padding);
    transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1), transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &.half-padding {
      --body-padding: 0.5rem;
    }

    @media #{$xlarge-up} {
      --body-padding: 3.75rem;

      width: calc(100% - #{$sidebar-width});
      max-width: $body-width;
      left: 50%;
      transform: translateX(calc(-50% + (#{$sidebar-width} / 2)));
    }

    &.no-padding {
      --body-padding: 0;
    }

    &.container-fluid {
      max-width: 100%;

      &.half-padding {
        padding: 0 .5rem .5rem;
      }

      &.no-padding {
        padding: 0;
      }

      @media #{$xlarge-up} {
        padding: 0 1rem 1rem;

        &.half-padding {
          padding: 0 .5rem .5rem;
        }

        &.no-padding {
          padding: 0;
        }
      }
    }
  }

  &.is-control-panel-opened {
    .search-bar {
      @media #{$xxlarge-custom-up} {
        left: #{$sidebar-width};
        width: calc(100% - (#{$sidebar-width} + #{$control-panel-width}));
      }

      @media #{$xxxlarge-up} {
        width: calc(100% - (#{$sidebar-width} + #{$control-panel-large-width}));
      }
    }

    .header,
    .banner,
    .footer {
      @media #{$xxlarge-custom-up} {
        left: #{$sidebar-width};
        width: calc(100% - (#{$sidebar-width} + #{$control-panel-width}));
      }

      @media #{$xxxlarge-up} {
        width: calc(100% - (#{$sidebar-width} + #{$control-panel-large-width}));
      }
    }

    .body {
      @media #{$xxlarge-custom-up} {
        left: 50%;
        width: calc(100% - (#{$sidebar-width} + #{$control-panel-width}));
        transform: translateX(calc(-50% + (#{$sidebar-width} / 2) - #{$control-panel-width} / 2));
      }

      @media #{$xxxlarge-up} {
        width: calc(100% - (#{$sidebar-width} + #{$control-panel-large-width}));
        transform: translateX(calc(-50% + (#{$sidebar-width} / 2) - #{$control-panel-large-width} / 2));
      }
    }
  }

  .cdk-global-scrollblock {
    .search-bar,
    .operation-search {
      position: fixed;
    }

    .header,
    .banner {
      &:not(.has-not-search) {
        margin-top: 60px;
      }
    }
  }

  &.is-compact {
    .search-bar {
      @media #{$xxlarge-custom-up} {
        left: #{$sidebar-compact-width};
        width: calc(100% - #{$sidebar-compact-width});
      }
    }

    .header,
    .banner,
    .footer {
      @media #{$xxlarge-custom-up} {
        left: #{$sidebar-compact-width};
        width: calc(100% - #{$sidebar-compact-width});
      }
    }

    /* stylelint-enable */

    .body {
      @media #{$xxlarge-custom-up} {
        width: calc(100% - #{$sidebar-compact-width});
        transform: translateX(calc(-50% + (#{$sidebar-compact-width} / 2)));
      }
    }

    &.is-control-panel-opened {
      .search-bar {
        @media #{$xlarge-up} {
          left: #{$sidebar-compact-width};
          width: calc(100% - #{$sidebar-compact-width});
        }

        @media #{$xxlarge-custom-up} {
          left: #{$sidebar-compact-width};
          width: calc(100% - (#{$sidebar-compact-width} + #{$control-panel-width}));
        }

        @media #{$xxxlarge-up} {
          width: calc(100% - (#{$sidebar-compact-width} + #{$control-panel-large-width}));
        }
      }

      .header,
      .banner,
      .footer {
        @media #{$xlarge-up} {
          left: #{$sidebar-compact-width};
          width: calc(100% - #{$sidebar-compact-width});
        }

        @media #{$xxlarge-custom-up} {
          left: #{$sidebar-compact-width};
          width: calc(100% - (#{$sidebar-compact-width} + #{$control-panel-width}));
        }

        @media #{$xxxlarge-up} {
          width: calc(100% - (#{$sidebar-compact-width} + #{$control-panel-large-width}));
        }
      }

      .body {
        @media #{$xlarge-up} {
          width: calc(100% - #{$sidebar-compact-width});
          transform: translateX(calc(-50% + (#{$sidebar-compact-width} / 2)));
        }

        @media #{$xxlarge-custom-up} {
          width: calc(100% - (#{$sidebar-compact-width} + #{$control-panel-width}));
          transform: translateX(calc(-50% + (#{$sidebar-compact-width} / 2) - (#{$control-panel-width} / 2)));
        }

        @media #{$xxxlarge-up} {
          width: calc(100% - (#{$sidebar-compact-width} + #{$control-panel-large-width}));
          transform: translateX(calc(-50% + (#{$sidebar-compact-width} / 2) - (#{$control-panel-large-width} / 2)));
        }
      }
    }
  }
}

.sidebar-menu-item {
  transition: background-color 200ms ease-in-out;

  &.is-admin {
    a {
      color: var(--purple);
    }
  }

  &:hover {
    background-color: var(--gray-200);

    &.is-admin {
      background-color: var(--purple-100);
    }
  }

  $item: &;

  &.active {
    background-color: var(--primary-700);

    &.is-admin {
      background-color: var(--purple);
    }

    a {
      color: var(--white);

      #{$item}__icon {
        color: var(--white);

        .primary {
          color: var(--primary);
        }
      }
    }
  }
}

.dark-mode .sidebar-menu-item.active {
  background-color: var(--primary);
}

.user-menu-dropdown {
  .dropdown-options-container {
    margin-left: 22px;
  }
}
