.header-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: rem-calc(16) 0;
}

.content-page {
  padding: rem-calc(32);
  background-color: var(--white);
  border-color: 1px solid var(--gray-300);
  border-radius: 4px;
}
