@import "../../abstracts/index";
@import "stepper-step-title";
@import "stepper-step-subtitle";
@import "stepper-step-icon";
@import "stepper-step-separator";

:root {
  --step-icon-size: 24px;
}

.stepper {
  .step {
    &.current {
      --step-text-color: var(--primary-700);
    }

    &.next {
      --step-text-color: var(--gray-350);
    }

    &.pending {
      --step-separator-moving-line-color: var(--green);
      --step-text-color: var(--primary-700);
    }

    &.success {
      --step-separator-moving-line-color: var(--green);
      --step-text-color: var(--gray-350);
    }

    &.error {
      --step-text-color: var(--red-500);
    }

    &.tooearly {
      --step-text-color: var(--primary-700);
    }

    &.ineligible {
      --step-text-color: var(--primary-700);
    }
  }

  &.horizontal {
    --step-gap: 16px;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: var(--step-gap);

    .step {
      display: grid;
      grid-template-rows: repeat(3, auto);
      column-gap: 16px;
      text-align: center;
      font-size: rem-calc(11);
    }
  }

  &.vertical {
    .step {
      display: grid;
      grid-template-columns: 52px 1fr;
    }
  }

  &.tiny {
    --step-icon-size: 16px;
  }
}
