@import "../abstracts";

.datepicker {
  width: 100%;

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem-calc(8);

    &--centered {
      justify-content: center;
    }

    &__arrow {
      padding: 0 rem-calc(8);
      cursor: pointer;
      font-size: rem-calc($h6-size);
    }

    &:not(:hover) {
      color: var(--gray-350);
    }

    &__title {
      cursor: pointer;
      gap: rem-calc(8);

      & > i {
        font-size: rem-calc($h6-size);
      }
    }
  }

  &__day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;

    &__label {
      display: inline-block;
      text-align: center;
      font-size: rem-calc(14);
      padding: rem-calc(8);
    }
  }

  &__days,
  &__months,
  &__years {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(7, 1fr);

    &__label {
      padding: rem-calc(8);
      font-size: rem-calc(12);
      display: inline-block;
      text-align: center;
      cursor: pointer;
      border-radius: 4px;

      &.is-disabled {
        cursor: default;
        color: var(--gray-300);
      }

      &:hover:not(&--is-selected, &--is-start-date, &--is-end-date, &--is-preselected, .is-disabled) {
        background-color: var(--primary-200);
      }

      &--is-not-in-month {
        color: var(--gray-350);
      }

      &--is-today,
      &--is-this-year {
        color: var(--primary);
        font-weight: 600;
      }

      &--is-selected, &--is-start-date, &--is-end-date {
        color: var(--white);
        background-color: var(--primary);
      }

      &--is-start-date {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &--is-end-date {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--is-within-range {
        background-color: var(--primary-100);
        border-radius: 0;

        &:hover {
          background-color: var(--primary-200);
        }
      }

      &--is-preselected {
        background-color: var(--primary-200);
        border-radius: 0;
      }
    }
  }

  &__years,
  &__months {
    grid-template-columns: repeat(4, 1fr);
  }

  &__pills {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &__label {
      width: 100%;
      line-height: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
