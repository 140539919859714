@import "../abstracts";

.checkmark {
  display: inline-flex;
  width: 14px;
  height: 14px;
  border: 1px solid var(--gray-300);
  border-radius: 2px;
  margin-right: 8px;
  font-size: rem-calc(10);
  align-items: center;
  justify-content: center;
  color: var(--primary);
  flex-shrink: 0;

  &.selected {
    border: none;
    color: var(--white);

    &:not(.disabled) {
      background-color: var(--primary);
    }

    &.disabled {
      background-color: var(--gray-325);
    }
  }

  &.no-margin {
    margin-right: 0;
  }

  &.darker-border {
    border-color: var(--gray-325);
  }
}
