@each $i, $value in $spacers {
  .m-#{$i} {
    margin: rem-calc($value);
  }
  .mt-#{$i} {
    margin-top: rem-calc($value);
  }
  .mr-#{$i} {
    margin-right: rem-calc($value);
  }
  .mb-#{$i} {
    margin-bottom: rem-calc($value);
  }
  .ml-#{$i} {
    margin-left: rem-calc($value);
  }
  .my-#{$i} {
    margin-top: rem-calc($value);
    margin-bottom: rem-calc($value);
  }
  .mx-#{$i} {
    margin-left: rem-calc($value);
    margin-right: rem-calc($value);
  }
}
