/* stylelint-disable */
.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 16px;

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.is-vertical {
    flex-direction: column;
    grid-column-gap: 0;

    &:not(.no-vertical-row-gap){
      grid-row-gap: 8px;
    }
  }

  .radio-button {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    visibility: inherit;
    clip: rect(0, 0, 0, 0);
    display: none;

    &__appearance {
      display: inline-block;
      width: rem-calc(14);
      height: rem-calc(14);
      background-color: var(--white);
      border-radius: 50%;
      border: 1px solid var(--gray-300);
      position: relative;
    }

    &__label {
      font-size: rem-calc(14);
      color: var(--primary-700);
      display: flex;
      align-items: center;
      &--light {
        font-weight: 400 !important;
        color: var(--primary-700);
      }
    }

    &:disabled {
      + .radio-button {
        &__label {
          cursor: not-allowed;
        }

        .radio-button {
          &__appearance {
            background-color: var(--gray-300);
          }
        }
      }
    }

    &:checked {
      + .radio-button {
        &__label .radio-button {
          &__appearance::before {
            position: absolute;
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: var(--primary);
            border-radius: 50%;
            content: "";
            top: 2px;
            left: 2px;
          }
        }
      }
    }
  }

  &.yes-no {
    grid-column-gap: 8px;

    .radio-button {
      &__appearance {
        display: none;
      }

      &__label {
        padding: 8px 11px;
        border-radius: 4px;
        border: 1px solid var(--gray-300);
        font-weight: 600;
        transition: all 200ms ease;
        line-height: 17px;
        font-size: rem-calc(14);
      }

      &:checked {
        + .radio-button {
          &__label {
            color: var(--white);
            background-color: var(--primary);
            border-color: var(--primary);
          }

          &--no {
            background-color: var(--primary-700);
            border-color: var(--primary-700);
          }
        }
      }

      &:disabled {
        + .radio-button {
          &__label {
            cursor: not-allowed;
            border-color: var(--gray-300);
            color: var(--gray-325);
          }
        }

        &:checked + .radio-button {
          &__label {
            background-color: var(--gray-300);
          }
        }
      }
    }

    &.is-admin {
      .radio-button {
        &__label {
          border-color: var(--purple);
          color: var(--purple);
        }

        &:checked {
          + .radio-button {
            &__label {
              color: var(--white);
              background-color: var(--purple);
              border-color: var(--purple);
            }
          }
        }

        &:disabled {
          + .radio-button {
            &__label {
              border-color: var(--purple-200);
              color: var(--gray-325);
            }
          }

          &:checked + .radio-button {
            &__label {
              background-color: var(--purple-200);
            }
          }
        }
      }
    }
  }

  &.yes-no-outline {
    .radio-button {
      &__appearance {
        display: none;
      }

      &__label {
        border-radius: 4px;
        border: 1px solid var(--gray-300);
        font-weight: 600;
        transition: all 200ms ease;
        line-height: 120%;
        text-align: center;
        display: block;
        padding: 16px 24px;
      }

      &:checked {
        + .radio-button {
          &__label {
            color: var(--primary);
            background-color: var(--white);
            border-color: var(--primary);
          }
        }
      }

      &:disabled {
        + .radio-button {
          &__label {
            cursor: not-allowed;
            border-color: var(--gray-300);
            color: var(--gray-325);
          }
        }

        &:checked + .radio-button {
          &__label {
            background-color: var(--gray-300);
          }
        }
      }
    }
  }
}
