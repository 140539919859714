.partner-settings-card {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray-300);

  &__content {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 16px;

    &__sticky {
      position: sticky;
      top: 55px;
    }
  }
}

.double-form-row {
  display: flex;
  grid-column-gap: 8px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
