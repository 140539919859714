a {
  color: var(--primary-700);
  line-height: inherit;

  &:hover,
  &.active {
    text-decoration: none;
  }

  &.small {
    font-size: rem-calc(14);
  }

  &.reverse {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  &.no-underline {
    text-decoration: none;
  }
}
