.select {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  cursor: pointer;

  .placeholder,
  .select-value {
    display: inline-flex;
    align-items: center;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: rem-calc(8);

    &.wrap {
      white-space: normal;
    }
  }
}
