.declaration-item {
  $item: &;

  &__validation {
    &__file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-column-gap: 16px;

      &__help {
        display: flex;
        align-items: center;

        &.pointer {
          cursor: pointer;
        }
      }
    }

    &__item {
      p {
        font-size: rem-calc(12);

        &.notice-message {
          font-size: rem-calc(14);
        }
      }
    }
  }

  &__wrapper {
    &.root {
      margin-bottom: 24px;
    }

    &.no-margin {
      margin-bottom: 0;
    }

    &.m-bold {
      #{$item}__body__label {
        font-weight: 600;
      }
    }

    &.root #{$item}__wrapper__main {
      padding: 8px 4px;
    }

    &.level-1 {
      > #{$item}__children {
        padding-left: 19px;
        margin-left: 7px;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          top: 4px;
          content: '';
          height: calc(100% - 8px);
          width: 2px;
          background-color: var(--gray-300);
        }
      }
    }

    &.level-2,
    &.level-3,
    &.level-4 {
      #{$item}__wrapper__main {
        padding: 4px;
      }

      > #{$item}__children {
        padding-left: 4px;
      }

      #{$item}__body__label {
        > p {
          font-size: rem-calc(12);
        }
      }

      #{$item}__value {
        > p {
          font-size: rem-calc(12);
        }
      }
    }

    &.level-4 {
      #{$item}__body__label {
        > p {
          font-size: rem-calc(10);
        }
      }

      #{$item}__value {
        > p {
          font-size: rem-calc(10);
        }
      }
    }

    &__main {
      &.is-expandable {
        cursor: pointer;
      }

      &.background-admin {
        background-color: var(--purple-100);
      }

      &.hover-background {
        &:hover {
          background-color: var(--gray-200);
        }
      }

      &.hover-effect {
        &:hover {
          padding-right: 16px;
          padding-left: 16px;
          transition-property: padding;
          transition-duration: .2s;
        }
      }
    }
  }

  &__body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    &__label {
      display: flex;
      align-items: center;

      &__help {
        cursor: pointer;
      }

      .no-wrap {
        white-space: nowrap;
      }

      > p {
        display: inline-block;
      }

      > h5 {
        display: inline-block;
      }
    }
  }

  &__line {
    flex: 1;
    border-top: 1px solid var(--gray-300);

    &.color-gray-325 {
      border-color: var(--gray-325);
    }
  }

  &__value {
    text-align: right;
    max-width: 350px;

    > p {
      line-height: 15px;
    }

    > h5 {
      white-space: nowrap;
    }

    &__input {
      input {
        width: 100px;
      }

      dougs-select {
        min-width: 100px;
      }
    }
  }

  &__sibling {
    font-size: rem-calc(10);
    font-weight: 400;
    min-width: 120px;

    &.align-right {
      text-align: right;
    }

    .no-wrap {
      white-space: nowrap;
    }
  }
}

.declaration-table {
  &__filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__periods {
      display: flex;
      align-items: center;
    }

    dougs-select {
      width: 150px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.declaration-table-data {
  $current: &;

  &__first-header {
    width: 200px;
  }

  &__first-cell {
    display: flex;
    align-items: center;
    grid-column-gap: rem-calc(8);
    justify-content: space-between;

    &.pointer {
      cursor: pointer;
    }
  }

  .level-1,
  .level-2,
  .level-3,
  .level-4 {
    #{$current}__first-cell {
      padding-left: rem-calc(32);
    }

    td, td > p {
      font-size: rem-calc(12);
    }
  }

  .level-2 {
    #{$current}__first-cell {
      padding-left: rem-calc(40);
    }
  }

  .level-3 {
    #{$current}__first-cell {
      padding-left: rem-calc(48);
    }
  }

  .level-4 {
    #{$current}__first-cell {
      padding-left: rem-calc(56);
    }
  }

  .pointer {
    cursor: pointer;
  }

  .operation-modal {
    text-decoration: underline;
    color: var(--primary);

    &:hover {
      text-decoration: none;
    }
  }
}

.declaration-list {
  &__title {
    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      dougs-select {
        width: 200px;
      }
    }
  }
}

.declaration-line {
  &.active {
    td {
      border-bottom: none;
    }
  }
}
