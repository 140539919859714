h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-title, sans-serif;
  color: var(--primary-700);
  font-weight: 600;
  line-height: 120%;
}

h1 {
  font-size: rem-calc($h1-size - 7);

  @include breakpoint($medium-up) {
    font-size: rem-calc($h1-size);
  }
}

h2 {
  font-size: rem-calc($h2-size - 7);

  @include breakpoint($medium-up) {
    font-size: rem-calc($h2-size);
  }
}

h3 {
  font-size: rem-calc($h3-size);
}

h4 {
  font-size: rem-calc($h4-size);
}

h5 {
  font-size: rem-calc($h5-size);
  margin: 0;
}

h6 {
  font-size: rem-calc($h6-size);
  margin: 0;
}
