@import "../abstracts";

.operation-list {
  position: relative;
  border-radius: 4px;
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  border-bottom: none;

  &__date {
    position: sticky;
    top: 60px;
    font-size: rem-calc(14);
    background-color: var(--gray-100);
    color: var(--gray);
    border-bottom: 1px solid var(--gray-300);
    z-index: 2;
    border-radius: 4px 4px 0 0;

    &__box-shadow {
      box-shadow: 0 4px 4px var(--gray-200);
    }
  }

  &__show-all {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--gray-100);
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid var(--gray-300);

    &:hover {
      background-color: var(--gray-300);
    }

    &__label {
      font-size: rem-calc(12);
    }
  }
}

.operation-container {
  position: relative;
  overflow: hidden;

  &.is-deleted {
    background-color: var(--red-200);
  }

  &__upload-files {
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--white-rgb) / 70%);
    z-index: 2;
    border: 1px dashed var(--primary);
  }

  .operation-detail {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid var(--gray-300);

    &:hover {
      background-color: var(--gray-100);
    }

    &__header {
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: auto auto;

      &.compact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
      }

      &__creator {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
      }

      &__divider {
        min-height: 100%;
        min-width: 2px;
      }

      &__date-container {
        align-self: center;
      }

      &__title {
        line-height: 1;
        display: flex;
        flex-direction: column;

        &.line-break-anywhere {
          line-break: anywhere;
        }

        &.compact {
          align-self: center;
          font-size: rem-calc(14);
        }

        span.compact {
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.2;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }


        &__additional-wording {
          display: block;
          font-size: rem-calc(12);
          color: var(--gray);
          font-family: "Open Sans", sans-serif;
        }

        &__memo {
          width: fit-content;
        }
      }

      &__date {
        font-size: rem-calc(18);
        font-weight: 600;
        text-align: center;
        line-height: 1;

        &__month {
          display: block;
          font-size: rem-calc(11);
          text-transform: uppercase;
        }
      }

      &__left {
        display: flex;
        align-items: stretch;
      }

      &__right {
        display: grid;
        align-items: center;
        grid-template-columns: 150px 175px auto;
        grid-gap: 24px;

        &.is-in-modal {
          grid-template-columns: minmax(150px, 1fr) auto 105px;
          width: 600px;

          @media #{$xlarge-down} {
            width: 460px;
          }
        }

        @media #{$large-up} {
          &:not(.is-in-modal) {
            grid-template-columns: 250px 200px auto;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-flow: column wrap;

      &__memo {
        width: fit-content;
      }
    }

    &__upload-files-text {
      visibility: hidden;
      position: absolute;
      z-index: 3;
      text-align: center;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      i {
        display: block;
        font-size: rem-calc(32);
        color: var(--primary);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.should-associate {
        margin-left: -9px;
      }

      &__button-validate,
      &__button-delete,
      &__button-edit {
        &__container {
          display: flex;
          align-items: center;
        }

        &__text {
          line-height: 1;
        }

        &__icon {
          display: inline-flex;
          width: 14px;
          height: 14px;
          background-color: rgb(var(--white-rgb) / 20%);
          color: var(--white);
          border-radius: 50%;
          font-size: rem-calc(8);
          align-items: center;
          justify-content: center;

          &.only-validate {
            font-size: rem-calc(12);
            background-color: transparent;
          }
        }
      }

      &__button-edit {
        &__icon {
          color: var(--primary-700);
        }
      }
    }
  }

  &.drag-over {
    .operation-container {
      &__upload-files {
        visibility: visible;
      }
    }

    .operation-detail {
      &__upload-files-text {
        visibility: visible;
      }
    }
  }
}
