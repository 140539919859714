.invoice-button {
  display: flex;
  align-items: center;
  font-size: rem-calc(14);
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
  line-height: 1.6;
  width: fit-content;

  &.tiny {
    font-size: rem-calc(12);
  }

  &.align-right {
    justify-content: flex-end;
  }
}

.invoice-detail-container {
  i {
    font-size: rem-calc(14);
    width: 14px;
    text-align: center;

    &.delete {
      cursor: pointer;

      &:hover {
        color: var(--red);
      }
    }
  }

  .field-without-icon {
    width: calc(100% - 20px);
  }
}

.invoice-table {
  table {
    border-collapse: collapse;

    thead {
      background-color: var(--gray-100);
      border-top: 1px solid var(--gray-300);
      border-bottom: 1px solid var(--gray-300);

      th {
        text-align: left;
        font-size: rem-calc(14);
        color: var(--gray);
        font-weight: normal;
        line-height: 120%;

        &.align-right {
          text-align: right;
        }

        .title-with-icon {
          display: flex;
          align-items: flex-end;
        }
      }
    }

    tbody {
      tr {
        vertical-align: top;

        td {
          border-bottom: 1px solid var(--gray-300);
          line-height: 1;

          &.align-right {
            text-align: right;
          }

          &.width-80 {
            width: 80px;
          }

          &.width-120 {
            width: 120px;
          }

          &.width-140 {
            width: 140px;
          }
        }

        &.has-discount {
          td {
            border-color: transparent;
          }
        }
      }
    }
  }
}

.dropdown-company-data {
  .dropdown-options-container {
    overflow-y: auto;
  }
}

.lines-arrow {
  width: 14px;

  .arrow {
    cursor: pointer;
    display: flex;
    flex-direction: column;

    i {
      font-size: rem-calc(12);
    }
  }
}
