@import 'swiper/swiper.min.css';

/* TODO Check why it does not work */

/* @import '~swiper/modules/navigation/navigation.min.css';
@import '~swiper/modules/pagination/pagination.min.css'; */

:root {
  --swiper-pagination-bullet-inactive-color: var(--gray-350);
  --swiper-pagination-color: var(--primary-700);
}

