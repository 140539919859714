.stepper {
  --step-separator-offset: 16px;
  --step-separator-height: 1px;
  --step-separator-width: calc(100% + var(--step-gap) - var(--step-icon-size) - var(--step-separator-offset) * 2);
  --step-separator-top: 11px;
  --step-separator-left: calc(50% + calc(var(--step-icon-size) / 2 + var(--step-separator-offset)));
  --step-separator-fixed-line-color: var(--gray-325);
  --step-separator-moving-line-width: 0;
  --step-separator-moving-line-height: 1px;

  .step {
    position: relative;

    .step-separator-wrapper {
      .step-separator {
        position: absolute;
        height: var(--step-separator-height);
        width: var(--step-separator-width);
        top: var(--step-separator-top);
        left: var(--step-separator-left);
        content: "";

        &.last {
          display: none;
        }

        &::before, &::after {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
        }

        &::before {
          width: 100%;
          height: 100%;
          background-color: var(--step-separator-fixed-line-color);
        }

        &::after {
          height: var(--step-separator-moving-line-height);
          width: var(--step-separator-moving-line-width);
          background-color: var(--step-separator-moving-line-color);
          z-index: 1;
          transition: all 0.4s ease;
        }
      }
    }
  }

  &.horizontal {
    .step {
      &.pending {
        .step-separator::after {
          animation: pending-horizontal-animation 2s linear infinite;
        }
      }

      &.success {
        --step-separator-moving-line-width: 100%;
      }
    }
  }

  &.vertical {
    --step-separator-offset: 8px;
    --step-separator-height: calc(100% - calc(var(--step-separator-offset) * 2));
    --step-separator-width: 1px;
    --step-separator-top: var(--step-separator-offset);
    --step-separator-left: 50%;
    --step-separator-moving-line-width: 1px;
    --step-separator-moving-line-height: 0;

    .step {
      .step-separator-wrapper {
        position: relative;
        min-height: 28px;
      }

      &.pending {
        --step-separator-moving-line-height: 50%;

        .step-separator::after {
          animation: pending-animation 2s linear infinite;
        }
      }

      &.success {
        --step-separator-moving-line-height: 100%;
      }
    }
  }

  &.tiny {
    --step-separator-offset: 12px;
    --step-separator-top: 7px;
  }
}

@keyframes pending-animation {
  0% {
    top: 0;
    bottom: 100%;
    height: 0;
  }

  10% {
    top: 0;
    bottom: 70%;
    height: 30%;
  }

  90% {
    top: 70%;
    bottom: 0;
    height: 30%;
  }

  100% {
    top: 100%;
    bottom: 0;
    height: 0;
  }
}

@keyframes pending-horizontal-animation {
  0% {
    left: 0;
    right: 100%;
    width: 0;
  }

  10% {
    left: 0;
    right: 70%;
    width: 30%;
  }

  90% {
    left: 70%;
    right: 0;
    width: 30%;
  }

  100% {
    left: 100%;
    right: 0;
    width: 0;
  }
}
