p {
  font-size: rem-calc(16);

  &.xtiny {
    font-size: rem-calc(11);
  }

  &.tiny {
    font-size: rem-calc(12);
  }

  &.small {
    font-size: rem-calc(14);
  }

  &.big {
    font-size: rem-calc(18);
  }

  &.inline-block {
    display: inline-block;
  }
}
