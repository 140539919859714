dougs-stepper-icon {
  &.current {
    --step-icon-background-color: transparent;
    --step-icon-border-size: 2px;
    --step-icon-border-color: var(--primary-700);
  }

  &.next {
    --step-icon-background-color: transparent;
    --step-icon-border-size: 1px;
    --step-icon-border-color: var(--gray-325);
  }

  &.pending {
    --step-separator-moving-line-color: var(--green);
    --step-icon-background-color: transparent;
    --step-icon-border-size: 0px;
    --step-icon-border-color: var(--green);
  }

  &.success {
    --step-separator-moving-line-color: var(--green);
    --step-icon-background-color: var(--green);
    --step-icon-border-size: 1px;
  }

  &.error {
    --step-icon-background-color: var(--red);
    --step-icon-border-size: 1px;
    --step-icon-border-color: var(--red);
  }

  &.tooearly {
    --step-icon-background-color: var(--primary-700);
    --step-icon-border-size: 1px;
    --step-icon-border-color: var(--primary-700);
  }

  &.ineligible {
    --step-icon-background-color: var(--primary-700);
    --step-icon-border-size: 1px;
    --step-icon-border-color: var(--primary-700);
  }

  .dougs-stepper-icon-container {
    margin: 0 auto;
    vertical-align: middle;
    position: relative;
    width: var(--step-icon-size);
    height: var(--step-icon-size);
    border-radius: 50%;
    transform: scale(1);
    border: var(--step-icon-border-size) solid var(--step-icon-border-color);
    transition: all .2s ease;
    background-color: var(--step-icon-background-color);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      fill: none;
      stroke: var(--white);
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all .3s ease;
      transition-delay: .1s;
      transform: translate(-50%, -50%);
    }

    i {
      font-size: rem-calc(14);
      color: var(--white)
    }

    .stepper-loader {
      height: var(--step-icon-size);
      width: var(--step-icon-size);
      display: block;
      animation: stepper-loader 3s linear infinite;

      &__loader {
        $loader-width: var(--step-icon-size);

        display: block;
        position: absolute;
        inset: 0;
        margin: auto;
        height: $loader-width;
        width: $loader-width;
        clip: rect(calc($loader-width / 2), $loader-width, $loader-width, 0);
        animation: stepper-loader 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;

        &::after {
          content: "";
          display: block;
          position: absolute;
          inset: 0;
          margin: auto;
          height: $loader-width;
          width: $loader-width;
          border: 1px solid var(--green);
          border-radius: 50%;
        }
      }
    }
  }

  &.success, &.error, &.ineligible, &.tooearly, &.current {
    .dougs-stepper-icon-container {
      animation: check .6s ease;

      svg {
        stroke-dashoffset: 0;
      }
    }
  }
}

.stepper {
  &.tiny {
    .dougs-stepper-icon-container {
      svg, i {
        display: none;
      }
    }
  }
}

@keyframes check {
  50% {
    transform: scale(1.2);
  }
}

@keyframes stepper-loader {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
