@import "../abstracts";

.drawer {
  height: 100%;
  width: 95%;
  right: 0;

  &.small {
    @media #{$medium-up} {
      width: 60%;
    }

    @media #{$xlarge-up} {
      width: 40%;
    }

    @media #{$xxxlarge-up} {
      width: 30%;
    }
  }

  &.medium {
    @media #{$medium-up} {
      width: 80%;
    }

    @media #{$xlarge-up} {
      width: 60%;
    }

    @media #{$xxxlarge-up} {
      width: 50%;
    }
  }

  &.large {
    @media #{$xlarge-up} {
      width: 85%;
    }

    @media #{$xxxlarge-up} {
      width: 75%;
    }
  }
}
