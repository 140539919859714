@each $i, $value in $spacers {
  .p-#{$i} {
    padding: rem-calc($value);
  }
  .pt-#{$i} {
    padding-top: rem-calc($value);
  }
  .pr-#{$i} {
    padding-right: rem-calc($value);
  }
  .pb-#{$i} {
    padding-bottom: rem-calc($value);
  }
  .pl-#{$i} {
    padding-left: rem-calc($value);
  }
  .py-#{$i} {
    padding-top: rem-calc($value);
    padding-bottom: rem-calc($value);
  }
  .px-#{$i} {
    padding-left: rem-calc($value);
    padding-right: rem-calc($value);
  }
}
