@import '../abstracts';

.assignation {
  &__to {
    &__dropdown {
      &__avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      span.select-value {
        display: flex;
        align-items: center;
      }
    }
  }
}

.cockpit-team-selector {
  &__team {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 21px;
      height: 21px;
      border-radius: 50%;
    }
  }

  &__department {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-325);
    color: var(--white);
    border-radius: 50%;
  }
}

.cockpit {
  &__tooltip {
    &__stats {
      display: grid;
      grid-template-columns: 10px 1fr 1fr;
      align-items: center;
      gap: 4px 8px;

      &__plain {
        grid-column: 1 / span 3;
      }

      &__badge {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &__white {
          background-color: var(--gray-300);
        }

        &__gray {
          background-color: var(--gray);
        }

        &__purple {
          background-color: var(--purple);
        }

        &__green {
          background-color: var(--green);
        }

        &__red {
          background-color: var(--red);
        }

      }
    }
  }
}

.cockpit-tabs-more {
  width: 160px;
}
