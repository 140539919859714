@import "../abstracts";

.modal-select-association-container {
  padding: rem-calc(24) rem-calc(16) rem-calc(8) rem-calc(16);
  max-width: 100%;
  width: 500px;

  &.no-overflow {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  dougs-form-field {
    margin-bottom: rem-calc(8);
  }

  &__scroll {
    overflow-y: auto;
  }
}

.select-candidate {
  list-style: none;
  margin: 0;
  padding: 0;

  $current: &;

  li {
    cursor: pointer;
    border-bottom: 1px solid var(--gray-300);
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--primary-700);
    font-size: rem-calc(14);

    &:last-child {
      border: none;
    }

    img {
      width: 20px;
    }

    &:hover {
      background-color: var(--gray-200);
    }

    p {
      font-weight: 400;
      color: var(--gray);
    }
  }
}
