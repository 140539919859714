@import '../abstracts';

fieldset {
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  position: relative;
  padding: 56px 16px 16px;

  legend {
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    color: var(--gray-350);
    background-color: var(--gray-200);
    height: 40px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    padding: 8px 16px;
  }
}
