@import '../abstracts';

.settings-card {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray-300);

  &__content {
    display: grid;
    grid-template-columns: 220px minmax(0, 1fr);
    grid-column-gap: 30px;
    padding: 32px;

    @media #{$xxlarge-up} {
      grid-template-columns: 290px minmax(0, 1fr);
    }

    &.admin {
      background-color: var(--purple-100);
    }

    &__two-columns {
      grid-column-gap: 16px;
    }
  }

  &__button-container {
    width: 100%;
    position: sticky;
    background-color: var(--gray-100);
    border-top: 1px solid var(--gray-300);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0;

    &.not-sticky {
      position: relative;
    }

    &--space-between {
      justify-content: space-between;
    }

    &.admin {
      background-color: var(--purple-100);
    }

    &.no-decoration {
      background-color: inherit;
      border-top: none;
    }
  }

  &__sticky-column-title {
    position: sticky;
    height: fit-content;

    .subtitle {
      font-size: rem-calc(14);
      line-height: 1.2;
    }

    top: 65px;
  }
}

.form-layout {
  grid-column-gap: 30px;
  width: 100%;
  display: flex;

  & > * {
    flex-grow: 1;
  }

  &__no-grow, & > dougs-button {
    flex-grow: 0;
  }

  &--no-grow {
    & > * {
      flex-grow: 0;
    }
  }

  &.space-between {
    justify-content: space-between;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &__two-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
  }

  &__two-columns__avatar {
    display: grid;
    grid-template-columns: 128px 1fr;
    grid-column-gap: 32px;

    &__name {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
      align-self: center;
    }
  }

  &__two-columns-small-first {
    display: grid;
    grid-template-columns: 90px 1fr;
    grid-column-gap: 16px;
  }
}
