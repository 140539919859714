.stepper {
  .step-title {
    align-self: center;
    font-size: rem-calc(12);
    font-weight: 600;
    color: var(--step-text-color);
    transition: color 0.5s;
  }

  &.tiny {
    .step-title {
      margin-top: 4px;
      font-size: rem-calc(10);
    }
  }

  &.horizontal {
    .step-title {
      margin-top: 8px;
    }

    &:not(.tiny) {
      .step-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
