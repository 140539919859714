joyride-step {
  .joyride-step {
    &__container {
      box-shadow: none;
      border-radius: 8px;
      padding: rem-calc(32);
      width: 480px;
      color: var(--primary-700);
      background-color: var(--white);
    }

    &__header {
      padding: 0;
    }

    &__body {
      padding: 0;
    }

    &__footer {
      margin-top: 24px;
      padding: 0;
      font-size: rem-calc(14);
      color: var(--gray)
    }

    &__buttons-container {
      align-items: center;
    }

    &__prev-container {
      cursor: pointer;
    }

    &__close {
      right: 20px;
      top: 15px;
      height: 12px;
      width: 12px;
      opacity: 0.6;
    }

    &__counter-container {
      .counter {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: var(--gray-300);
        border-radius: 50%;

        &.is-active {
          background-color: var(--primary);
        }
      }
    }

    &__holder {
      font-family: "Open Sans", sans-serif;
    }
  }

  .joyride-arrow {
    &__bottom {
      border-top-color: var(--white);
    }

    &__top {
      border-bottom-color: var(--white);
    }

    &__left {
      border-right-color: var(--white);
    }

    &__right {
      border-left-color: var(--white);
    }
  }
}
