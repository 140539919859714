.dropdown-options-container {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  margin-top: 4px;
  box-shadow: 0 12px 12px rgba(40 40 40 / 10%);
  position: relative;

  &.width-100 {
    width: 100%;
  }

  &.is-select {
    max-height: 230px;
  }

  &.is-datepicker {
    border: 1px solid var(--gray-300);
    box-shadow: 0 12px 12px rgba(40 40 40 / 10%);
    width: 350px;
    min-width: 350px;
  }

  &.has-arrow {
    &::before,
    &::after {
      content: ' ';
      width: 0;
      height: 0;
      border: solid transparent;
      bottom: 100%;
      left: 50%;
      position: absolute;
      pointer-events: none;
    }

    &::before {
      border-bottom-color: var(--gray-300);
      border-width: 7px;
      margin-left: -7px;
    }

    &::after {
      border-bottom-color: var(--white);
      border-width: 5px;
      margin-left: -5px;
    }
  }

  &.on-top {
    margin-top: 0;
    margin-bottom: 8px;

    &.has-arrow {
      &::before,
      &::after {
        bottom: 0;
        top: 100%;
      }

      &::before {
        border-top-color: var(--gray-300);
        border-bottom-color: transparent;
      }

      &::after {
        border-top-color: var(--white);
        border-bottom-color: transparent;
      }
    }
  }

  .scrollable {
    overflow: auto;
  }

  .dropdown-option {
    padding: rem-calc(8) rem-calc(16);
    cursor: pointer;
    font-size: rem-calc(14);
    color: var(--primary-700);
    display: flex;
    line-height: 1;
    align-items: center;

    &.small {
      font-size: rem-calc(12);
      padding: rem-calc(4) rem-calc(8);
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
      border: none;
    }

    &.selected {
      color: var(--primary-500);
    }

    &:hover:not(.group-title, .empty, .disabled),
    &.active:not(.disabled) {
      background-color: var(--primary-100);
    }

    &.disabled:not(.group-title) {
      color: var(--gray-325);
      background-color: var(--gray-200);
      cursor: not-allowed;
    }

    &.group-title {
      background-color: var(--primary-200);
      cursor: pointer;
      display: flex;
      grid-column-gap: 16px;
      justify-content: space-between;
      font-size: rem-calc(12);

      &.no-pointer {
        cursor: default;
      }
    }

    &.empty {
      cursor: default;
    }

    &.no-padding {
      padding: 0;
    }

    & > span {
      min-width: 0;
      hyphens: auto;
    }
  }

  .dropdown-search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray-300);
    padding-left: rem-calc(18);

    input {
      border: none;
      padding: rem-calc(12) rem-calc(18);
      line-height: 1;
      font-size: rem-calc(14);
      width: 100%;
      outline: none;
      background-color: var(--white);
      color: var(--primary-700);

      &::placeholder {
        color: var(--gray-325);
      }
    }

    &__icon-search {
      color: var(--gray);
      line-height: 1;
    }
  }
}
