@import "../abstracts/index";

.dougs-tabs {
  position: relative;
  border-bottom: 1px solid var(--gray-300);
  display: block;
  margin: 0 -32px;

  @media #{$large-down} {
    margin: 0 -16px;
  }

  .scroll-container {
    width: calc(100% - 64px);
    display: flex;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0 32px;

    @media #{$large-down} {
      padding: 0;
      width: 100%;
      margin: 0;
    }

    &.dragging {
      cursor: grab;
    }

  }

  &::-webkit-scrollbar {
    display: none;
  }
}

[dougs-tab] {
  display: block;
  padding: 0 16px 8px;
  text-decoration: none;
  font-size: rem-calc(14);
  color: var(--gray);
  border-bottom: 1px solid transparent;
  white-space: nowrap;
  position: relative;

  .missing-field-animation {
    right: -3px;
    left: auto;
    top: 3px;
    position: absolute;
  }

  &:hover, &.active {
    border-bottom-color: var(--primary-700);
    color: var(--primary-700);
  }

  &.admin {
    color: var(--purple);

    &:hover, &.active {
      border-bottom-color: var(--purple);
    }
  }
}

.tabs-pagination {
  padding: 0 4px;
  z-index: 2;
  position: absolute;

  &.start {
    background: linear-gradient(270deg, rgba(var(--white-rgb) / 0%) 0%, var(--white) 70%);
    left: 0;
    top: 0;
  }

  &.end {
    background: linear-gradient(90deg, rgba(var(--white-rgb) / 0%) 0%, var(--white) 70%);
    text-align: end;
    right: 0;
    top: 0;
  }

  i {
    padding: 4px;
    cursor: pointer;

    &:hover {
      outline: 1px solid var(--gray-300);
      border-radius: 4px;
    }
  }

  &.hidden {
    display: none;
  }
}
