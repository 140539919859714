@import "../abstracts";

.hide-on-mobile {
  @media #{$large-down} {
    display: none !important;
  }
}

.hide-on-desktop {
  @media #{$large-up} {
    display: none !important;
  }
}
